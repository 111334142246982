module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"bbea11ed-6b27-47c2-ac84-e082d29a6b41","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":1000},
    }]
